import React, { useState, useEffect, useRef, useContext } from "react";
import { auth, projectFirestore as firestore } from "../firebase";
import { Modal } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import CommunityContext from "./Contexts/CommunityContext";
import SubjectsContext from "./Contexts/SubjectContext";
import { UsersContext } from "./Contexts/UsersContext";

const Quizzes = ({ data, subject, topic, students, grade, type }) => {
  const [user] = useAuthState(auth);
  const [submitted, setSubmitted] = useState(false);
  const [results, setResults] = useState([]);
  const [answeredQuestionsLog, setAnsweredQuestionsLog] = useState([]);
  const [filter, setFilter] = useState("all"); // "all" or "unanswered"
  const allMethods = Array.from(new Set(data.flatMap((quiz) => quiz.methods)));
  const [selectedMethod, setSelectedMethod] = useState("All Methods");
  const [numberOfQuizzes, setNumberOfQuizzes] = useState(5); // State for number of quizzes to display
  const [filteredData, setFilteredData] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0); // State for tracking current quiz index

  const { users } = useContext(UsersContext);

  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);

  const submittedRef = useRef(null);

  useEffect(() => {
    if (user) {
      const currentUser = users.find((u) => u.uid === user.uid);
      if (currentUser && currentUser.answeredQuestionsLog) {
        setAnsweredQuestionsLog(currentUser.answeredQuestionsLog);
      }
    }
  }, [user, users]);

  useEffect(() => {
    const filterData = () => {
      const filtered = data
        .filter((quiz) => {
          if (quiz.topic !== topic) return false;
          if (
            selectedMethod !== "All Methods" &&
            !quiz.methods.includes(selectedMethod)
          ) {
            return false;
          }
          if (filter === "unanswered") {
            return !answeredQuestionsLog.some((entry) =>
              entry.quizResults.some((r) => r.question === quiz.question)
            );
          }
          return true;
        })
        .slice(0, numberOfQuizzes);

      setFilteredData(filtered);
      setSelectedAnswers(new Array(filtered.length).fill(null)); // Reset selected answers
      setCurrentQuizIndex(0); // Reset current quiz index to the first quiz
    };

    filterData();
  }, [data, topic, selectedMethod, filter, numberOfQuizzes, answeredQuestionsLog]);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const handleMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const handleAnswerSelection = (quizIndex, answerIndex) => {
    const newSelectedAnswers = [...selectedAnswers];
    newSelectedAnswers[quizIndex] = answerIndex;
    setSelectedAnswers(newSelectedAnswers);
  };

  const handleNext = () => {
    if (currentQuizIndex < filteredData.length - 1) {
      setCurrentQuizIndex(currentQuizIndex + 1);
    }
  };

  const handleBack = () => {
    if (currentQuizIndex > 0) {
      setCurrentQuizIndex(currentQuizIndex - 1);
    }
  };

  const handleSubmit = async () => {
    const newResults = filteredData
      .map((quiz, quizIndex) => {
        const selectedAnswerIndex = selectedAnswers[quizIndex];
        if (selectedAnswerIndex !== null && selectedAnswerIndex !== undefined) {
          const isCorrect = quiz.answers[selectedAnswerIndex].is_correct;
          return {
            topic: quiz.topic,
            subject: quiz.subject,
            grade: quiz.grade,
            question: quiz.question,
            isCorrect,
            methods: quiz.methods,
            correctAnswer: quiz.answers.find((answer) => answer.is_correct)
              .answer_text,
          };
        } else {
          // Handle case where answer is not selected
          return null;
        }
      })
      .filter((result) => result !== null); // Remove any null results

    if (newResults.length === 0) {
      // Handle case where no answers are selected
      return;
    }

    setResults(newResults);
    setSubmitted(true);

    try {
      // Fetch the existing answered questions log for the user
      const userDocRef = firestore.collection("usersCollection").doc("usersDoc");
      const doc = await userDocRef.get();
      if (doc.exists) {
        const { users } = doc.data();
        const currentUser = users.find(u => u.uid === user.uid);
       
        if (currentUser) {
          let answeredQuestionsLog = currentUser.answeredQuestionsLog || [];
         
          // Add the new log entry only if the question hasn't been answered before
          const unansweredResults = newResults.filter(
            (result) =>
              !answeredQuestionsLog.some((entry) =>
                entry.quizResults.some((r) => r.question === result.question)
              )
          );
  
          if (unansweredResults.length > 0) {
            const answeredQuestionsLogEntry = {
              quizResults: unansweredResults,
            };
            answeredQuestionsLog.push(answeredQuestionsLogEntry);
            console.log("answered questions log", answeredQuestionsLog);
            // Update the entire answeredQuestionsLog array in Firestore
            await userDocRef.update({
              users: users.map(user => user.uid === currentUser.uid ? { ...user, answeredQuestionsLog } : user)
            });
          }
        } else {
          console.error("User not found");
        }
      }
    } catch (error) {
      console.error("Error updating answered questions log:", error);
    }
    if (submittedRef.current) {
      submittedRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const currentQuiz = filteredData[currentQuizIndex];

  return (
    <div>
      <div className="filter-buttons w-100">
        <p
          className={`m-1 btn ${filter === "unanswered" ? "btn-outline-dark" : "btn-outline-secondary"}`}
          onClick={() => handleFilterChange("unanswered")}
          disabled={submitted}
        >
          Not answered Questions
        </p>
        <p
          className={`m-1 mx-2 btn ${filter === "all" ? "btn-outline-dark" : "btn-outline-secondary"}`}
          onClick={() => handleFilterChange("all")}
          disabled={submitted}
        >
          All Questions
        </p>
      </div>
      <div className="d-flex my-4">
        <div style={{ maxWidth: "200px" }} className="method-filter">
          <select
            id="methodSelect"
            value={selectedMethod}
            onChange={handleMethodChange}
            disabled={submitted}
          >
            <option value="All Methods">Select Topic</option>
            {allMethods.map((method, index) => (
              <option key={index} value={method}>
                {method}
              </option>
            ))}
          </select>
        </div>
        <div style={{ maxWidth: "200px" }} className="select-quizzes mx-2">
          <select
            id="numberOfQuizzes"
            value={numberOfQuizzes}
            onChange={(e) => setNumberOfQuizzes(parseInt(e.target.value))}
            disabled={submitted}
          >
            <option value={5}>5 questions</option>
            <option value={10}>10 questions</option>
            <option value={20}>20 questions</option>
            <option value={data.length}>All</option>
          </select>
        </div>
      </div>

      {currentQuiz && (
        <div>
          <div>
            <p className="text-primary fw-bolder">{currentQuizIndex + 1}.</p>
            {user.displayName === currentQuiz.displayName && (
              <p
                onClick={(e) =>
                  values.deleteQuizFromSubject(e, currentQuiz.subject, currentQuiz.quizId)
                }
                className="fw-bolder text-danger"
              >
                delete question
              </p>
            )}
            <p className="text-primary fw-bolder">
              <span dangerouslySetInnerHTML={{ __html: currentQuiz.question }} />
            </p>
          </div>
          <div className="d-flex flex-wrap">
            {currentQuiz.answers.map((answer, answerIndex) => (
              <button
                key={answerIndex}
                className={`btn btn-outline-primary m-2 ${selectedAnswers[currentQuizIndex] === answerIndex ? "active" : ""
                  }`}
                onClick={() => handleAnswerSelection(currentQuizIndex, answerIndex)}
                disabled={submitted}
              >
                <span dangerouslySetInnerHTML={{ __html: answer.answer_text }} />
              </button>
            ))}
          </div>
        </div>
      )}

      <div className="d-flex justify-content-between my-3">
        <button
          className="btn btn-secondary"
          onClick={handleBack}
          disabled={currentQuizIndex === 0 || submitted}
        >
          Back
        </button>
        {currentQuizIndex === filteredData.length - 1 ? (
          <button
            className="btn btn-success"
            onClick={handleSubmit}
            disabled={submitted}
          >
            Submit your answers
          </button>
        ) : (
          <button
            className="btn btn-primary"
            onClick={handleNext}
            disabled={submitted}
          >
            Next
          </button>
        )}
      </div>

      <Modal show={submitted} onHide={() => setSubmitted(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Quiz Results</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {submitted && (
            <div ref={submittedRef}>
              <h3>Results:</h3>
              {results.length > 0 ? (
                <ul>
                  {results.map((result, index) => (
                    <li key={index}>
                      {result.question}:{" "}
                      {result.isCorrect ? (
                        <span className="fw-bolder text-success">"Correct"</span>
                      ) : (
                        <span className="fw-bolder text-danger">"Incorrect"</span>
                      )}
                      <div>
                        <p>
                          Correct Answer :{" "}
                          <span
                            className="fw-bolder text-success"
                            dangerouslySetInnerHTML={{
                              __html: result.correctAnswer,
                            }}
                          ></span>
                        </p>
                        <hr />
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p className="text-success fw-bolder">Submitted!</p>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Quizzes;
