import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DisplayLesson from "./DisplayLesson";
import SubjectsContext from "./Contexts/SubjectContext";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Payment from "../Payment/Payment";
import ReviewForm from "./ReviewForm";
import LearnerPayment from "../Payment/LearnerPayment";

function Subject() {
  const location = useLocation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const values = useContext(SubjectsContext);
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (location?.state?.data) {
      setData(location.state.data);
      setLoading(false); // Data has been loaded, set loading to false
    }
  }, [location]);

  if (loading) {
    return <div>Loading...</div>; // Display a loading message or spinner
  }

  const route = `${data?.name}/lesson/l/lesson/l`;

  return (
    <div>
      <div className="container mt-5 mb-5">
        {data?.students?.map((student) => student).includes(user?.displayName) ? (
          <div>
            <p>Enrolled</p>
          </div>
        ) : (
          <div>
            <p className="text-info my-3">Join subject to view all classes</p>
            <p
              onClick={() => values.addStudentToSubject(data?.name, user?.displayName)}
              className="btn btn-success px-4 py-2 btn-animate"
            >
              Join Subject
            </p>
          </div>
        )}
      </div>
      <div className="container">
        <DisplayLesson subject={data?.name} route={route} />
      </div>
      <div className="container">
        <ReviewForm SubjectName={data?.name} subject={data} type={"subject"} />
      </div>
    </div>
  );
}

export default Subject;
