import React, { useContext, useState } from "react";
import SubjectsContext from "./Contexts/SubjectContext";
import { v4 } from "uuid";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles


function AddContent({ subjectId }) {
  const values = useContext(SubjectsContext);
  const the_subject = values.subjects.filter(
    (subject) => subject.name === subjectId
  );

  const [isCreating, setIsCreating] = useState(false);
  const [createClass, setCreateClass] = useState(false);
  const [addLearningOutcomes, setAddLearningOutcomes] = useState(false);
  const [learningOutcomes, setLearningOutcomes] = useState([]);
  const [learningOutcome, setLearningOutcome] = useState("");
  const [courseStructure, setCourseStructure] = useState("");
  const [lesson, setLesson] = useState([]);
  const l_id = v4();
  const [file_name1, setFileName1] = useState("");
  const [file_name2, setFileName2] = useState("");
  const [file_name3, setFileName3] = useState("");
  const [file_name4, setFileName4] = useState("");
  const [file_name5, setFileName5] = useState("");

  const [fileInfo1, setFileInfo1] = useState("");
  const [fileInfo2, setFileInfo2] = useState("");
  const [fileInfo3, setFileInfo3] = useState("");
  const [fileInfo4, setFileInfo4] = useState("");
  const [fileInfo5, setFileInfo5] = useState("");

  const [lessonAttributes, setLessonAttributes] = useState({
    topic: "",
    description: "",
    l_id,
    summary: "",
    file1: null,
    file_name1,
    file2: null,
    file_name2,
    file3: null,
    file_name3,
    file4: null,
    file_name4,
    file5: null,
    file_name5,
  });

  // Set the file size limit to 30 MB (30 * 1024 * 1024 bytes)
  const FILE_SIZE_LIMIT = 60 * 1024 * 1024;

  const handleAddLearningOutcome = () => {
    if (learningOutcome) {
      setLearningOutcomes([learningOutcome]); // Set the single learning outcome
    }
  };

  const handleAddLesson = () => {
    setLesson([...lesson, ""]);
  };

  const handleLessonAttributeChange = (value, attributeName) => {
    setLessonAttributes((prevAttributes) => ({
      ...prevAttributes,
      [attributeName]: value,
    }));
  };

  const handleFileChange = (e, fileIndex) => {
    const file = e.target.files[0];
    if (!file) return;

    if (file.size > FILE_SIZE_LIMIT) {
      alert("File size exceeds the 60 MB limit. Please upload a smaller file.");
      return;
    }

    const fileName = file.name;
    const fileInfoSetter = {
      1: setFileInfo1,
      2: setFileInfo2,
      3: setFileInfo3,
      4: setFileInfo4,
      5: setFileInfo5,
    }[fileIndex];

    const fileNameSetter = {
      1: setFileName1,
      2: setFileName2,
      3: setFileName3,
      4: setFileName4,
      5: setFileName5,
    }[fileIndex];

    fileInfoSetter(fileName);
    fileNameSetter(fileName);
    setLessonAttributes((prev) => ({
      ...prev,
      [`file${fileIndex}`]: file,
      [`file_name${fileIndex}`]: fileName,
    }));
  };

  const handleSave = async () => {
    handleAddLearningOutcome();
    if (learningOutcomes.length === 0) {
      return;
    }
    setIsCreating(true);

    try {
      await values.addContentToSubject(
        learningOutcomes,
        courseStructure,
        subjectId
      );

      setLearningOutcome("");
      setCourseStructure([]);
      setIsCreating(false);
    } catch (error) {
      setIsCreating(false);
      alert("Error adding content:", error.message);
    }
  };

  const handleLessonSave = async () => {
    handleAddLesson();
    if (lesson.length === 0) {
      return;
    }
    setIsCreating(true);

    try {
      await values.addLessonToSubject(lesson, subjectId, lessonAttributes);

      setLesson([]);
      setLessonAttributes({
        topic: "",
        description: "",
        summary: "",
        file1: null,
        file2: null,
        file3: null,
        file4: null,
        file5: null,
      });

      setFileName1("");
      setFileName2("");
      setFileName3("");
      setFileName4("");
      setFileName5("");

      setIsCreating(false);
      alert("Class Created Successfully!");
    } catch (error) {
      setIsCreating(false);
      alert("Error adding class:", error.message);
    }
  };

  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, 3, false] }],
        ["italic"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["link", "image"],
        [{ align: [] }],
        ["code-block"],
        ["clean"],
      ],
    },
  };

  return (
    <div className="my-5">
      {/* Render Learning Outcomes Section */}
      {!the_subject[0]?.learningOutcomes?.length > 0 ? (
        <>
          {addLearningOutcomes ? (
            <div className="my-5">
              <button
                onClick={() => setAddLearningOutcomes(false)}
                className="btn btn-danger"
              >
                close add learning outcomes
              </button>
              <h6 className="my-4">
                Add Learning Outcomes
              </h6>
              <ReactQuill
                value={learningOutcome}
                onChange={(value) => setLearningOutcome(value)}
                placeholder="Write Learning Outcome"
                className="react-quill m-1 me-2 my-5"
              />
              <button
                className="btn btn-outline-dark text-primary m-2"
                onClick={handleSave}
                disabled={isCreating}
              >
                {isCreating
                  ? "Loading..."
                  : "Click Twice To Save Learning Outcomes"}
              </button>
            </div>
          ) : (
            <div className="my-5">
              <button
                onClick={() => setAddLearningOutcomes(true)}
                className="btn btn-success"
              >
                add learning outcomes
              </button>
            </div>
          )}
        </>
      ) : (
        <></>
      )}

      <hr />
      <br />

      {/* Render Create Class Section */}
      {!createClass ? (
        <>
          <button
            className="btn btn-success"
            onClick={() => setCreateClass(true)}
          >
            Create Class
          </button>
        </>
      ) : (
        <div className="mt-3">
          <button
            className="btn btn-warning mx-2"
            onClick={() => setCreateClass(false)}
          >
            Close Create Class
          </button>

          <h6 className="my-4 mx-2 text-success">Add Class Content</h6>
          <h6 className="text-primary fw-bolder">Write Topic</h6>
          <ReactQuill
            name="topic"
            value={lessonAttributes.topic}
            onChange={(e) => handleLessonAttributeChange(e, "topic")}
            placeholder="Topic"
            className="react-quill m-1 me-2 my-5"
          />

          <h6 className="text-success fw-bolder">Write Description</h6>
          <ReactQuill
            name="description"
            value={lessonAttributes.description}
            onChange={(e) => handleLessonAttributeChange(e, "description")}
            placeholder="Description"
            className="react-quill m-1 me-2 my-5"
          />

          <h6 className="text-primary fw-bolder">Write Class Content</h6>
          <ReactQuill
            name="summary"
            value={lessonAttributes.summary}
            onChange={(e) => handleLessonAttributeChange(e, "summary")}
            placeholder="Class Content"
            className="react-quill m-1 me-2 my-5"
            modules={modules}
            style={{ height: "400px", minHeight: "40px" }}
          />
         

          <h6>Upload Class Files</h6>
          <p className="my-2">
            Files may include videos, audio, documents, and/or pictures
          </p>
          <p className="my-2">
            Files may be 30mb or less
          </p>

          {/* Render File Inputs */}
          {[1, 2, 3, 4, 5].map((index) => (
            <div className="my-2" key={index}>
              <input
                type="file"
                onChange={(e) => handleFileChange(e, index)}
              />
              {eval(`fileInfo${index}`) && (
                <p>Selected file: {eval(`fileInfo${index}`)}</p>
              )}
            </div>
          ))}

          <button
            className="btn btn-outline-primary m-3"
            onClick={handleLessonSave}
            disabled={isCreating}
          >
            {isCreating ? "Loading..." : "Click Twice To Save Class"}
          </button>
        </div>
      )}
    </div>
  );
}

export default AddContent;
