import React, { useState, useContext, useEffect, useRef } from "react";
import { projectFirestore } from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import ReactPlayer from "react-player";
import Message from "./Message";
import { auth } from "../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import SubjectQuiz from "./SubjectQuiz";
import MarkdownRenderer from './MarkdownRenderer';
import SubjectsContext from "./Contexts/SubjectContext";
import QuizScores from "./QuizScores";
import Quiz from './Quiz'
import Quizzes from "./Quizzes";

function DisplayContent({ lesson_id, createQuiz, route, lesson_info }) {

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);
  
  const contentDisplayRef = useRef(null);
  const values = useContext(SubjectsContext);
  const subject = values.subjects?.filter((sub) => sub.name === lesson_info.subject_id)

  const [user] = useAuthState(auth);


  const [chat, setChat] = useState(false);
  const [classFiles, setClassFiles] = useState(false);
  const [quiz, setQuiz] = useState(false);
  const [quizScores, setQuizScores] = useState(false);
  const [currentFileIndex, setCurrentFileIndex] = useState(0);



  // Function to scroll to content display section
  const scrollToContentDisplay = () => {
    if (contentDisplayRef.current) {
      contentDisplayRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleQuiz = (e) => {
    e.preventDefault();
    setQuiz(true);
    setChat(false);
    setQuizScores(false);
    setClassFiles(false);
    scrollToContentDisplay();
  };
  const handleQuizScores = (e) => {
    e.preventDefault();
    setQuizScores(true);
    setQuiz(false);
    setChat(false);
    setClassFiles(false);
    scrollToContentDisplay();
  };
  const handleClassFiles = (e) => {
    e.preventDefault();
    setClassFiles(true);
    setQuizScores(false);
    setQuiz(false);
    setChat(false);
    scrollToContentDisplay();
  };
  const handleChat = (e) => {
    e.preventDefault();
    setChat(true);
    setQuiz(false);
    setQuizScores(false);
    setClassFiles(false);
    scrollToContentDisplay();
  };

  const handleContentClick = (fileIndex) => {
    setCurrentFileIndex(fileIndex);
    setClassFiles(true);
    setChat(false);
    setQuiz(false);
    setQuizScores(false);
    scrollToContentDisplay();
  };

  const handleContentClickHide = (e) => {
    e.preventDefault();
    setClassFiles(false);
    setChat(false);
    setQuiz(false);
    setQuizScores(false);
    scrollToContentDisplay();
  };


  const getFileType = (fileUrl) => {
    const extension = fileUrl?.split(".").pop().toLowerCase();
    const imageExtensions = ["jpg", "jpeg", "png", "gif"];
    const audioExtensions = ["mp3", "wav", "ogg"];
    const videoExtensions = ["mp4", "avi", "mkv", "mov"];
    const documentExtensions = [
      "pdf",
      "doc",
      "docx",
      "xls",
      "xlsx",
      "ppt",
      "pptx",
    ];

    if (imageExtensions.includes(extension)) {
      return "Image";
    } else if (audioExtensions.includes(extension)) {
      return "Audio";
    } else if (videoExtensions.includes(extension)) {
      return "Video";
    } else if (documentExtensions.includes(extension)) {
      return "Document";
    } else {
      return "File";
    }
  };

  const renderFileIcon = (fileType) => {
    switch (fileType) {
      case "Image":
        return <i className="fas fa-file-image"></i>;
      case "Audio":
        return <i className="far fa-file-audio"></i>;
      case "Video":
        return <i className="fas fa-file-video"></i>;
      case "Document":
        return <i className="far fa-file-alt"></i>;
      default:
        return <i className="far fa-file"></i>;
    }
  };


  return (
    <div className="container mt-5">
      <h6>Class Content</h6>
      <div className="mt-5 mb-5">
        <div className="row g-0">
          <div className="col-lg-3 col-md-12">
            <div className="content-grid" id="sidebar-wrapper">
              <div className="border-end bg-white">
                <div className="list-group list-group-flush">
                  <a
                    className="list-group-item list-group-item-action list-group-item-light p-3"
                  >
                    Select Content
                  </a>
                  <a
                    onClick={handleContentClickHide}
                    className={`list-group-item list-group-item-action list-group-item-light p-3 `}
                    href="#!"
                  >

                    <span className="text-info fw-bolder">
                      Class Lesson
                    </span>
                  </a>
                  <a
                    onClick={handleChat}
                    className={`list-group-item list-group-item-action list-group-item-light p-3 `}
                    href="#!"
                  >

                    <span className="text-info fw-bolder">
                      Chat
                    </span>
                  </a>
                  <a
                    onClick={handleQuiz}
                    className={`list-group-item list-group-item-action list-group-item-light p-3 `}
                    href="#!"
                  >
                    <span className="text-info fw-bolder">
                      Quiz
                    </span>

                  </a>
                  <a
                    onClick={handleQuizScores}
                    className={`list-group-item list-group-item-action list-group-item-light p-3 `}
                    href="#!"
                  >
                    <span className="text-info fw-bolder">
                      Quiz Scores
                    </span>

                  </a>
                  {[1, 2, 3, 4, 5].map((index) => (
                    <div key={index}>
                      {lesson_info[`file_name${index}`] &&
                        lesson_info[`fileUrl${index}`] && (
                          <a
                            onClick={() => handleContentClick(index - 1)}
                            className={`content-label list-group-item list-group-item-action list-group-item-light p-3 ${currentFileIndex === index - 1 ? "active" : ""
                              }`}

                          >
                            <span className="text-primary fw-bolder">
                              {renderFileIcon(getFileType(lesson_info[`file_name${index}`]))}
                              {" "}
                              {lesson_info[`file_name${index}`].substring(0, 20)}
                            </span>
                          </a>
                        )}
                    </div>
                  ))}
                  <a
                    className={`list-group-ite  btn btn-success  p-3 `}
                    onClick={(e) => values.addStudentToLesson(e, lesson_info.subject_id, lesson_info.lesson_id, user.displayName)}
                  >
                  Click After Completing Class
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-md-12" ref={contentDisplayRef}>
            <div id="page-content-wrapper" >
              <nav className="navbar navbar-expand-lg navbar-light bg-light border-bottom">
                <div className="container-fluid" >
                  <h6 className="">Content display</h6>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupported"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse"
                    id="navbarSupported"
                  >
                    <ul className="navbar-nav ms-auto mt-2 mt-lg-0">
                      <li className="nav-item active">
                        <a onClick={handleChat} className="nav-link" href="#!">
                          Chat
                        </a>
                      </li>
                      <li className="nav-item">
                        <a onClick={handleQuiz} className="nav-link" href="#!">
                          Quiz
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>

              <div className="container-fluid">
                <section className="w-100">
                  <div className="w-100">
                    <div className="w-100 px-0 mx-0 h-100">
                      {chat || quizScores || quiz || classFiles ? (
                        <></>
                      ) : (
                        
                          <div className="col mb-5 w-100 px-0 mx-0 content-container">
                            
                              <div className="card-body p-2">
                                <div className="text-start">
                                  <p className=" fw-bolder">
                                    <div dangerouslySetInnerHTML={{ __html: lesson_info.topic }}></div>
                                  </p>
                                </div>
                                <div className="text-start text-primary ">

                                  <div dangerouslySetInnerHTML={{ __html: lesson_info.description }}></div>
                                </div>
                                <div className="text-start">
                                  <div dangerouslySetInnerHTML={{ __html: lesson_info.summary }}></div>
                                </div>
                              </div>

                            </div>
                          
                      )}
                      <div className="w-100">
                        {chat ? (
                          <div>
                            <Message
                              path={`${lesson_info.subject_id}`}
                              type={'subject'}
                              messages={lesson_info}
                            />
                          </div>
                        ) : quiz ? (
                          <div style={{ height: '100%', maxHeight: '500px', overflowY: 'auto' }} className="mt-4">
                            <Quizzes data={subject[0].quizzes && subject[0].quizzes}

                              subject={lesson_info.subject_id}
                              topic={lesson_info.lesson_id}
                              students={subject[0]?.students}
                              grade={lesson_info.topic}
                              type={"subject"}

                            />
                            {/*subject[0]?.quizzes?.length > 0 ? subject[0]?.quizzes?.map((quiz, index) => (
                              <Quiz key={index} quizData={quiz} />
                            )) : <p>No Quizzes To Display Yet</p>*/}

                          </div>
                        ) : quizScores ? (
                          <div className="my-3">
                            <QuizScores
                              subject={lesson_info.subject_id}
                              topic={lesson_info.lesson_id}
                              students={subject[0]?.students}
                              grade={lesson_info.topic}
                            />
                          </div>
                        ) :
                        classFiles ? (
                          <div className="my-3">
                            <>
                          <div className="col mb-5 w-100 px-0 mx-0 content-container">
                            <div className="w-100">
                              <div className="content-files w-100">
                                {getFileType(
                                  lesson_info[
                                  `file_name${currentFileIndex + 1}`
                                  ]
                                ) === "Audio" ? (
                                  <div className="audio my-3 mx-0 p-0">
                                    <audio
                                      src={
                                        lesson_info[
                                        `fileUrl${currentFileIndex + 1}`
                                        ]
                                      }
                                      controls
                                    />
                                  </div>
                                ) : getFileType(
                                  lesson_info[
                                  `file_name${currentFileIndex + 1}`
                                  ]
                                ) === "Video" ? (
                                  <div className="video player-wrapper">
                                    <ReactPlayer
                                      url={
                                        lesson_info[
                                        `fileUrl${currentFileIndex + 1}`
                                        ]
                                      }
                                      width="100%"
                                      height="100%"
                                      wrapper="div"
                                      className="react-player px-0 mx-0"
                                      controls
                                    />
                                  </div>
                                ) : getFileType(
                                  lesson_info[
                                  `file_name${currentFileIndex + 1}`
                                  ]
                                ) === "Image" ? (
                                  <div className="image">
                                    <img
                                      src={
                                        lesson_info[
                                        `fileUrl${currentFileIndex + 1}`
                                        ]
                                      }
                                      alt="..."
                                    />
                                  </div>
                                ) : getFileType(
                                  lesson_info[
                                  `file_name${currentFileIndex + 1}`
                                  ]
                                ) === "Document" ? (
                                  <div className="document">
                                    <a
                                      href={
                                        lesson_info[
                                        `fileUrl${currentFileIndex + 1}`
                                        ]
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      {
                                        lesson_info[
                                        `file_name${currentFileIndex + 1}`
                                        ]
                                      }
                                    </a>
                                  </div>
                                ) : (
                                  <div>
                                    <p></p>
                                  </div>
                                )}
                              </div>
                              <div className="card-body p-2">
                                
                              </div>

                            </div>
                          </div>
                        </>
                          </div>
                        ) :
                         (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisplayContent;
