import React, { useState,useContext } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CommunityContext from "./Contexts/CommunityContext";
import SubjectsContext from "./Contexts/SubjectContext";

const ChallengeReviewForm = ({subjectName,challengeId,responseId,type}) => {
  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);
  
  
  const [score, setScore] = useState(0);
  const [summary, setSummary] = useState("");

  
  const handleScoreChange = (e) => {
    setScore(parseInt(e.target.value));
  };

  const handleSummaryChange = (value) => {
    setSummary(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (score && summary) {
       values.addReviewToResponse(subjectName,challengeId,responseId,score,summary)
      setScore(0);
      setSummary("");
    } else {
      alert("Please fill in all fields.");
    }
  };

  return (
    <form onSubmit={handleSubmit}>
     
      <div>
        <label>
          Score: (0 - 5)
          <input
            type="number"
            min={0}
            max={5}
            value={score}
            onChange={handleScoreChange}
          />
        </label>
      </div>
      <div>
        <label>
          Your review:
          <ReactQuill
            value={summary}
            onChange={handleSummaryChange}
            modules={{
              toolbar: [
                [{ header: [1, 2, 3, 4, false] }],
                ["bold", "italic", "underline", "strike"],
                [{ list: "ordered" }, { list: "bullet" }],
                ["link", "image"],
                ["clean"],
              ],
            }}
            formats={[
              "header",
              "list",
              "bullet",
              "link",
              "image",
            ]}
          />
        </label>
      </div>
      <button className="btn btn-success my-4 px-3 py-2" type="submit">Submit Review</button>
    </form>
  );
};

export default ChallengeReviewForm;
