import React, { useContext, useState, useEffect } from "react";
import { projectFirestore, auth } from "../firebase";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { useAuthState } from "react-firebase-hooks/auth";
import { Link } from "react-router-dom";
import SubjectsContext from "./Contexts/SubjectContext";
import Challenges from "./Challenges";
import CreateChallenges from "./CreateChallenges";

function DisplayLesson({ subject, route }) {
  const [user] = useAuthState(auth);
  const values = useContext(SubjectsContext);
  const [createChallenges, setCreateChallenges] = useState(false);
  const [studentRankings, setStudentRankings] = useState([]);
  const [learningOutcomesOpen, setLearningOutcomesOpen] = useState(false); // State for managing dropdown open/close

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const subjectsRef = projectFirestore.collection(route);
  const query = subjectsRef.orderBy("createdAt");

  const [lessons, loading] = useCollectionData(query, { idField: "id" });

  let isDisabled = true;
  const getSubject = values.subjects?.filter((sub) => sub.name === subject);

  // Calculate student's progress
  const totalLessons = getSubject?.[0]?.lessons?.length || 0;
  const lessonsCompleted = getSubject?.[0]?.lessons?.filter((lesson) =>
    lesson.students?.includes(user.displayName)
  ).length || 0;

  useEffect(() => {
    // Calculate student rankings
    if (getSubject && getSubject[0]) {
      const studentRankingsObj = {};
      getSubject[0].lessons?.forEach((lesson) => {
        lesson.students?.forEach((student) => {
          if (studentRankingsObj[student]) {
            studentRankingsObj[student]++;
          } else {
            studentRankingsObj[student] = 1;
          }
        });
      });

      // Convert the rankings into an array of objects
      const rankingsArray = Object.keys(studentRankingsObj).map((student) => ({
        student,
        lessonsIncluded: studentRankingsObj[student],
      }));

      // Sort the rankings in descending order
      rankingsArray.sort((a, b) => b.lessonsIncluded - a.lessonsIncluded);

      setStudentRankings(rankingsArray);
    }
  }, [getSubject]);

  // If the data is still loading or getSubject doesn't exist, return early
  if (loading) {
    return <div>Loading lessons...</div>;
  }

  if (!getSubject || !getSubject[0]) {
    return <div>No subject found or the data is still loading.</div>;
  }

  const getEnrolled = getSubject?.map((sub) =>
    sub.students?.includes(user.displayName)
  );

  if (getEnrolled && getEnrolled[0] === true) {
    isDisabled = false;
  }

  if (getSubject[0]?.displayName === user.displayName) {
    isDisabled = false;
  }

  return (
    <>
      <div>
        <section className="py-2 mt-1 mb-1">
          <div className="mt-1">
            <div>
              {/* Dropdown for learning outcomes */}
              <button
                className="btn btn-outline-success mb-3"
                onClick={() => setLearningOutcomesOpen(!learningOutcomesOpen)}
              >
                {learningOutcomesOpen ? "Close" : "Read"} Learning Outcomes
              </button>
              {learningOutcomesOpen && (
                <div className="mb-4">
                  <h4 className="text-success">Learning Outcomes</h4>
                  <div className="my-lis">
                    {getSubject[0]?.learningOutcomes?.map((outcome, index) => (
                      <span className="my-3" key={index}>
                        <div dangerouslySetInnerHTML={{ __html: outcome }}></div>
                      </span>
                    ))}
                  </div>
                </div>
              )}
              <hr />
              <br />
            </div>

            <h5 className="mb-3">Classes</h5>

            <div className="progress my-5">
              <div
                className="progress-bar text-success"
                role="progressbar"
                style={{
                  width: `${(lessonsCompleted / totalLessons) * 100}%`,
                }}
                aria-valuenow={lessonsCompleted}
                aria-valuemin={0}
                aria-valuemax={totalLessons}
              >
                {`${lessonsCompleted} of ${totalLessons} Lessons Completed`}
              </div>
            </div>

            <div className="mx-2 my-3">
              {getSubject?.[0]?.lessons?.map((lesson, index) => (
                <div className="my-5 lesson-delete" key={index}>
                  <p className="fw-bolder">
                    Class {index + 1} -{" "}
                    {lesson.students?.includes(user.displayName) ? (
                      <span className="text-success">Completed</span>
                    ) : (
                      <span className="text-warning">Incomplete</span>
                    )}
                  </p>
                  <br />
                  <Link
                    to={`${lesson.l_id}`}
                    state={{ data: lesson }}
                    style={{ textDecoration: "none" }}
                    className={`my-div ${index > 1 && isDisabled ? "disabled" : ""}`}
                  >
                    <div className="col ">
                      <div
                        className={`btns ${
                          lesson.students?.includes(user.displayName)
                            ? "texts-success"
                            : "texts-primary"
                        } w-100`}
                      >
                        <div className="card-bod p-1">
                          <div>
                            <div className="text-start">
                              <p className="fw-bolder text-dark">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: lesson.topic,
                                  }}
                                ></div>
                              </p>
                              <hr />
                            </div>
                            <div className="text-start">
                              <p>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: lesson.description.slice(0, 300),
                                  }}
                                ></div>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <button className="mt-3 p-2 bg-dark">
                        <Link
                          to={`${lesson.l_id}`}
                          state={{ data: lesson }}
                          style={{ textDecoration: "none" }}
                          className={`my-div ${index > 1 && isDisabled ? "disabled" : ""}`}
                        >
                          Open Class Content
                        </Link>
                      </button>
                    </div>
                  </Link>
                  <div>
                    {user.displayName === getSubject[0]?.displayName ? (
                      <>
                        <button
                          onClick={(e) =>
                            values.deleteLesson(
                              e,
                              getSubject[0].sub_id,
                              lesson.lesson_id
                            )
                          }
                          className="btn btn-outline-danger my-2"
                        >
                          <i className="fas fa-trash"></i>
                        </button>
                      </>
                    ) : null}
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          </div>
        </section>

        <div className="container my-3">
          <p className="mx-1 my-5 fw-bolder">Subject Questions / Notes</p>

          <Challenges challenges={getSubject[0]?.challenges} type="subject" />

          {createChallenges ? (
            <div className="my-2">
              <button
                onClick={() => setCreateChallenges(false)}
                className="btn btn-dark text-light my-3"
              >
                Close send question
              </button>
              <CreateChallenges subjectId={getSubject[0]?.name} type="subject" />
            </div>
          ) : (
            <>
              <button
                onClick={() => setCreateChallenges(true)}
                className="btn btn-success text-light my-3"
              >
                Send question
              </button>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default DisplayLesson;
