import React, { useContext, useState } from "react";
import CommunityContext from "./Contexts/CommunityContext";
import SubjectsContext from "./Contexts/SubjectContext";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const ChallengesSubmission = ({ subjectName, challengeId, type }) => {

  let cont;

  if (type === "community") {
    cont = CommunityContext;
  } else {
    cont = SubjectsContext;
  }

  const values = useContext(cont);

  const [file, setFile] = useState(null);
  const [summary, setSummary] = useState("");
  const [loading, setLoading] = useState(false);


  const handleSubmit = async (e) => {
    e.preventDefault();


    if (!summary) {
      alert("Please fill in the Heading.");
      return;
    }

    setLoading(true);

    const responseAttributes = {
      filename: "",
      summary,
    };

    await values.addResponseToChallenge(subjectName, challengeId, responseAttributes);

    setFile(null);
    setSummary("");
    setLoading(false);
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ["bold"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  };

  return (
    <div>
      <h6 className="mt-5 fw-bolder text-success">Send your answer</h6>
      <form onSubmit={handleSubmit}>

        <ReactQuill
          value={summary}
          onChange={setSummary}
          placeholder="Write your summary..."
          modules={modules}
          className="my-3"
        />

       

        {loading ? (
          <button className="mt-4 btn btn-success py-2 px-2">sending...</button>
        ) : (
          <button className="mt-4 btn btn-success py-2 px-2" type="submit">Send Your Answer</button>
        )}
      </form>
    </div>
  );
};

export default ChallengesSubmission;
