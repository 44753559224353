import React, { useRef, useState } from "react";
import { projectFirestore, auth } from "../firebase";
import firebase from "firebase/compat/app";
import DisplayLesson from "./DisplayLesson";
import { v4 } from "uuid";
import AddContent from "./AddContent";
import CreateChallenges from "./CreateChallenges";

function CreateLesson({ subject, path }) {

  const [createChallenges, setCreateChallenges] = useState(false);

  const route = `${path}/lesson/l`

  const dummy = useRef();
  const nameRef = useRef()
  const descriptionRef = useRef()
  const subjectsRef = projectFirestore.collection(route);

  const sendSubject = async (e) => {

    e.preventDefault();

    const { uid, photoURL } = auth.currentUser;
    const l_id = v4();
    const subjectName = nameRef.current.value;
    if (subjectName === "") {
      alert("enter the name");
      return
    }
    const querySnapshot = await subjectsRef.where('name', '==', subjectName).get();

    if (!querySnapshot.empty) {

      alert('name already exists , try another name');
      return;
    }

    const newSubject = {
      name: subjectName,
      description: descriptionRef.current.value,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      l_id,
      photoURL,
    };

    await subjectsRef.add(newSubject);

    dummy.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="container xs-container-fluid mt-5 mb-5">

      <p>Create Classes</p>

      <AddContent subjectId={subject} />

      <main className="subject-wrapper">
        <DisplayLesson subject={subject} route={route} />
        <span ref={dummy}></span>
      </main>


     
    </div>
  );
}

export default CreateLesson;
